body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (min-width: 320px) {
  .cardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: 4px;
  }
  .card {
    width: 43%;
    flex-basis: 43%;
    padding: 10px;
  }
  .cardTitle {
    font-size: 14px;
    color: rgb(81, 80, 80);
  }
  .cardFigure {
    font-size: 20px;
    color: rgb(30 30 30);
    font-weight: 700;
  }
  .cardTrend {
    font-size: 12px;
  }
  .cardTrend.raise {
    color: rgb(51 152 41);
  }
  .cardTrend.down {
    color: #f44336;
  }
  .tabContainer {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .MuiButtonBase-root.tabLabel {
    font-size: 14px;
  }
  .orderInfoCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  .orderInfoCard {
    flex: 42%;
    border-radius: 15px;
    color: #fff;
    pointer-events: all;
    cursor: pointer;
    /* flex-basis: 20%; */
  }
  .orderInfoCard p {
    color: #fff;
  }
  .MuiPaper-root.c1 {
    background-color: #c4a10c;
  }
  .MuiPaper-root.c2 {
    background-color: #75151e;
  }
  .MuiPaper-root.c3 {
    background-color: #2c5545;
  }
  .MuiPaper-root.c4 {
    background-color: #c93c20;
  }
  .MuiPaper-root.c5 {
    background-color: #49678d;
  }
  .orderInfoCard-figure {
    font-size: 40px;
    font-weight: 700;
    text-align: center;
  }
  .orderInfoCard-heading {
    font-size: 22px;
    font-weight: 500;
    text-align: center;
  }
}
@media (min-width: 768px) {
  .cardContainer {
    display: flex;
    flex-direction: row;
    gap: 12px;
  }
  .card {
    flex: 1;
    padding: 10px;
  }
  .cardTitle {
    font-size: 18px;
    color: rgb(81, 80, 80);
  }
  .cardFigure {
    font-size: 30px;
    color: rgb(30 30 30);
    font-weight: 700;
  }
  .cardTrend {
    font-size: 16px;
  }
  .orderInfoCardContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 16px;
  }
  .orderInfoCard {
    flex: 1;
  }
}
